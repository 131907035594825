@import '../common/variables.scss';

#carousel-layout {
    height: fit-content;
    width: 100vw;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#carousel-title-and-selector {
    display: flex;
    align-items: center;
}

#carousel {
    width: 60%;
    text-align: center;
}

#sliders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.slideInner___2mfX9.carousel__inner-slide {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
}

.carousel-image {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 20px;
}

#button-back {
    position: absolute;
    top: 48%;
    left: 0;
    transform: translate(-110%, -50%);
    color: $white
}

.tag-layout {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.avail-tags {
    display: flex;
    align-items: center;
    background-color: $orange;
    color: $white;
    font-weight: 400;
    letter-spacing: 0.02rem;
    width: fit-content;
    border-radius: 0 20px 20px 0;
    min-width: 170px;
    height: 40px;
    padding: 5px;
    padding-left: 15px;
}

#button-next {
    position: absolute;
    top: 48%;
    right: 0;
    transform: translate(110%, -50%);
    color: $white
}

.arrows {
    background-color: $dark-blue;
    border-radius: 90px;
    width: 40px;
    height: 40px;
    padding-top: 5px;
    border: none;
}

.dot-group {
    display: flex;
    align-items: center;
    justify-content: center;

    .carousel__dot {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        margin: 2% 4px 0 4px;
        padding: 0;
        background-color: #c3c4ca;
    }

    .carousel__dot--selected {
        width: 16px;
        height: 8px;
        border-radius: 10px;
        background-color: $dark-blue;
        transition: background 0.4s ease;
    }
}

#carousel-title {
    color: $black;
    font-family: $p-font;
    margin: 0;
    margin-bottom: 2%;
    font-size: 2.5rem;
}

#carousel-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

#carousel-title-section {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 110%;
}

#carousel-house-title {
    color: $black;
    font-family: $p-font;
    margin: 0;
    font-size: 1.4rem;
}

#carousel-bed-bath {
    color: $black;
    font-weight: 400;
    font-family: $p-font;
    font-style: italic;
    margin: 0;
    margin-left: 1%;
    margin-bottom: 0.11rem;
    font-size: 1rem;
}

#info-list {
    width: 110%;
    height: fit-content;
    display: inline-grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    margin: 1vh 0 1.5vh 2vw;
}

.list-items {
    font-family: $p-font;
    color: $black;
    font-weight: 400;
    margin: 5px 0 5px 0;
}

#carousel-button-section {
    display: flex;
    width: 50%;
    justify-content: center;
}

.carousel-button {
    background-color: $orange;
    text-align: center;
    border: none;
    border-radius: 40px;
    font-family: $header-font;
    color: $white;
    font-size: 1.5rem;
    padding: 8px;
    width: 30%;
    margin: 0 4%;
    cursor: pointer;
    text-decoration: none;
}

//Phone screen
@media screen and (max-width: 660px) {
    #carousel-layout {
        margin-top: 10%;
    }

    #carousel-title-and-selector {
        display: block;
    }

    #carousel {
        min-width: 300px;
    }

    .bi {
        font-size: 2rem;
    }

    .carousel__dot {
        width: 1px;
        height: 12px;
        margin: 1px;
        border-radius: 50%;
        border: 1px solid $black;
    }

    #carousel-title-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    #carousel-title {
        font-size: 1.4rem;
        margin-bottom: 3%;
    }

    #carousel-house-title {
        font-size: 1.2rem;
    }

    #carousel-bed-bath {
        color: $black;
        font-weight: 400;
        font-family: $p-font;
        font-style: italic;
        margin: 0;
        margin-left: 1%;
        font-size: 0.95rem;
    }

    #info-list {
        width: 90%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.8rem;
    }

    .list-items {
        margin: 2px;
    }

    #carousel-button-section {
        display: flex;
        align-items: center;
    }

    .carousel-button {
        font-size: 1rem;
        padding: 4px;
    }

    .avail-tags {
        width: auto;
        min-width: 115px;
        height: 14px;
        padding: 5px;
        font-size: 0.5rem;
    }

}

//mid-size like tablet
@media screen and (max-width: 1200px) and (min-width: 661px) {
    .carousel-button {
        font-size: 1.2rem;
        padding: 8px;
    }

    #carousel-title-and-selector {
        display: block;
    }

    .list-items {
        margin: 5px;
    }

    .avail-tags {
        font-size: 0.9rem;
    }
}
